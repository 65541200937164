import axios, { AxiosInstance } from 'axios';
import React from 'react';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'next-i18next';
import { toastr } from 'react-redux-toastr';
import createApiClient from '../api/api';
import { ME_QUERY_KEY } from './use-me-query';
import apiConfig from '../api/api.config';
import getAydApiErrorResponse from '../../utils/get-ayd-api-error-response/get-ayd-api-error-response';
import { ErrorCode } from '../types/error-response';

const useApiClient = (): AxiosInstance => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return React.useMemo(
    () =>
      createApiClient(apiConfig, error => {
        if (getAydApiErrorResponse(error)?.error.code === ErrorCode.UnsupportedApiVersion) {
          toastr.clean();
          toastr.error(
            t('ERRORS.UNSUPPORTED_API_VERSION.TITLE'),
            t('ERRORS.UNSUPPORTED_API_VERSION.DESCRIPTION')
          );
        }
        if (
          axios.isAxiosError(error) &&
          (error.response?.status === 403 || error.response?.status === 401)
        ) {
          queryClient.setQueryData(ME_QUERY_KEY, null);
        }
      }),
    [queryClient, t]
  );
};

export default useApiClient;
