import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import * as Sentry from '@sentry/nextjs';
import { AYD_API_VERSION, XSRF_COOKIE_NAME } from '../constants';

export const XSRF_HEADER_NAME = 'X-XSRF-TOKEN';
export const API_VERSION_HEADER_NAME = 'X-API-VERSION';

const createApiClient = (
  apiConfig: AxiosRequestConfig,
  onError: (error: any) => void
): AxiosInstance => {
  const apiClient = axios.create({
    ...apiConfig,
    xsrfCookieName: XSRF_COOKIE_NAME,
    xsrfHeaderName: XSRF_HEADER_NAME,
    withXSRFToken: true,
  });

  apiClient.interceptors.request.use(originalConfig => {
    const config = { ...originalConfig };
    config.headers.Accept = 'application/json';
    config.headers['X-Requested-With'] = 'XMLHttpRequest';
    config.headers[API_VERSION_HEADER_NAME] = AYD_API_VERSION;
    return config;
  });

  apiClient.interceptors.response.use(
    response => response,
    error => {
      onError(error);
      Sentry.captureException(error);
      throw error;
    }
  );

  return apiClient;
};

export default createApiClient;
